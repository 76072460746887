// 图片域名
const url = 'https://source.se6250.com/'
// app下载地址
const sourceUrl = 'https://source.gettrx.org/download/get-trx.apk'
// api请求
const baseUrl = 'https://api.se6250.com/loan'
// 店铺域名
const storeHost = 'https://seller.shopeee1.com/';
// 语言转换
const lang = {
    null: 'english',
    English: 'english',
    日本語: 'japanese',
    Português: 'portuguese',
    繁體中文: 'chineseTraditional',
    简体中文: 'chinese',
    Français:'french',
    Deutsch:'german',
    Türk:'turkish',
    Español:'spanish',
    عربي: 'arabic',
    한국인: 'korean',
    Suomi: 'finland',
    Русский:'russian',
    italiano: 'italian',
    แบบไทย: 'thai',
    Nederlands: 'dutch',
}

/*******************************************************************/
// 店铺注册页
const shopRegisterWebUrl = '#/join-index?roteType=1'

// 店铺注册页
const shopLoginWebUrl = '#/login/loginIndex'

export default {
    url,
    sourceUrl,
    baseUrl,
    lang,
    shopRegisterWebUrl,
    storeHost,
    shopLoginWebUrl
}